import { Tag } from "@/types";
import { useEffect, useState } from "react";
import useApi from "./useApi";
import useSubscribe from "./useSubscribe";

export default function useContactTags(contactId?: number) {
  const [all, setAll] = useState<Tag[]>([]);
  const [selected, setSelected] = useState<Tag[]>([]);
  const api = useApi();
  const subscribe = useSubscribe();

  useEffect(() => {
    async function fetchAll() {
      const resp = await api.messaging.get("ContactTags/All");
      setAll(await resp.json());
    }

    fetchAll();
  }, [api.messaging]);

  useEffect(() => {
    async function fetchSelected() {
      if (!contactId) {
        return;
      }
      const resp = await api.messaging.get(
        `ContactTags/Selected?contactId=${contactId}`
      );
      setSelected(await resp.json());
    }

    fetchSelected();
  }, [api.messaging, contactId]);

  function save(tags: Tag[]) {
    if (!contactId) {
      return;
    }
    setSelected(tags);

    api.messaging.post("ContactTags/Associate", {
      contactId,
      tags: tags.map((t) => t.name.trim())
    });
  }

  function remove(tag: Tag) {
    if (!contactId) {
      return;
    }
    setSelected((selected) => selected.filter((t) => t.name !== tag.name));

    api.messaging.post("ContactTags/Remove", {
      contactId,
      tag: tag.name.trim()
    });
  }

  useEffect(
    () =>
      subscribe("contact-tags-updated", (message) => {
        setAll(message.allTags);

        if (message.contactId === contactId) {
          setSelected(message.selectedTags);
        }
      }),
    [contactId, subscribe]
  );

  return { all, selected, save, remove };
}
