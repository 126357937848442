import Modal from "@velaro/velaro-shared/src/components/Modal";
import useApi from "@/hooks/useApi";
import { Conversation } from "@/types";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  conversation: Conversation;
  onClose(): void;
}

export default function ResolveConversationModal(props: Props) {
  const [topic, setTopic] = useState(props.conversation.topic);
  const [submitting, setSubmitting] = useState(false);
  const [resolvedBy, setResolvedBy] = React.useState<string>();
  const api = useApi();
  const navigate = useNavigate();
  const toastAlert = useToastAlert();
  const translation = useTranslations();

  React.useEffect(() => {
    setResolvedBy(props.conversation.resolvedBy);
  }, [props.conversation.resolvedBy]);

  async function handleSubmit() {
    setSubmitting(true);

    const resp = await api.messaging.post(
      `Conversations/${props.conversation.id}/Resolve`,
      {
        topic
      }
    );

    if (resp.ok) {
      navigate("/conversations");
    } else {
      setSubmitting(false);
      toastAlert.displayToast(Severity.Error, "An unexpected error occurred");
    }
  }

  return (
    <Modal show={true}>
      <Modal.Body>
        <div className="flex">
          <button className="ml-auto h-10 w-10" onClick={() => props.onClose()}>
            <i className="fa fa-times"></i>
          </button>
        </div>
        <div className="px-4 pb-4">
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="mb-3">
                <label className="block text-center text-lg mb-2">
                  {translation.key("add_conversation_topic")}
                </label>
                <input
                  type="text"
                  className="block w-full input"
                  autoFocus
                  maxLength={30}
                  required
                  value={topic}
                  onChange={(e) => setTopic(e.currentTarget.value)}
                />
                <div className="text-right text-xs">
                  {translation.key("max_thirty_character")}
                </div>
              </div>
              <div className="flex">
                <button
                  type="submit"
                  className="btn-green ml-auto"
                  disabled={submitting}
                >
                  {submitting
                    ? translation.key("saving") + "..."
                    : translation.key("save")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
