import React, { useCallback } from "react";
import useProfile from "@/hooks/useProfile";
import UnreadMessagesContext from "@/context/UnreadMessagesContext";
import useSubscribe from "@/hooks/useSubscribe";
import useApi from "@/hooks/useApi";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";

export default function UnreadMessagesProvider(props: {
  children: React.ReactNode;
}) {
  const [loaded, setLoaded] = React.useState(false);
  const [map, setMap] = React.useState<{ [id: number]: number }>({});

  const [
    currentConversationId,
    setCurrentConversationId
  ] = React.useState<number>();

  const api = useApi();
  const { profile } = useProfile();
  const subscribe = useSubscribe();
  const toastAlert = useToastAlert();

  const fetchUnreadMessages = useCallback(async () => {
    console.log("fetching unread messages");
    const resp = await api.messaging.get("UnreadMessages");

    if (resp.ok) {
      setMap(await resp.json());
      setLoaded(true);
    } else {
      toastAlert.displayToast(
        Severity.Error,
        "Failed to fetch unread messages"
      );
    }
  }, [api.messaging, toastAlert]);

  React.useEffect(() => {
    fetchUnreadMessages();
  }, [fetchUnreadMessages]);

  React.useEffect(
    () =>
      subscribe("conversation-assigned", (message) => {
        if (message.user.id === profile.id) {
          fetchUnreadMessages();
        }
      }),
    [subscribe, profile.id, fetchUnreadMessages]
  );

  const serverClear = React.useCallback(async () => {
    console.log("clearing unread messages", currentConversationId);
    toastAlert.displayToast(Severity.Info, "Clearing unread messages");

    setMap((oldState) => {
      const newState = { ...oldState };
      newState[currentConversationId!] = 0;
      return newState;
    });

    await api.messaging.post("UnreadMessages/Clear", {
      conversationId: currentConversationId
    });
  }, [api.messaging, currentConversationId, toastAlert]);

  // clear unread message count when conversation is selected.

  React.useEffect(() => {
    if (currentConversationId && loaded) {
      serverClear();
    }
  }, [currentConversationId, loaded, serverClear]);

  function getCount(conversationId: number) {
    return map[conversationId] || 0;
  }

  React.useEffect(
    () =>
      subscribe("receive-message", (msg) => {
        const wasMe = profile.id === msg.message.user?.id;

        if (wasMe) {
          return;
        }

        // If the conversation is assigned to someone else, don't increment.
        if (
          msg.assignedBotId ||
          (msg.assignedUserId && msg.assignedUserId !== profile.id)
        ) {
          return;
        }

        // If the conversation is currently selected, don't increment
        // and clear state on the server.
        if (currentConversationId === msg.conversationId) {
          serverClear();
          return;
        }

        incrementCountForConversation(msg.conversationId);
      }),
    [currentConversationId, profile.id, serverClear, subscribe]
  );

  React.useEffect(
    () =>
      subscribe("conversation-created", (msg) => {
        incrementCountForConversation(msg.conversation.id);
      }),
    [subscribe]
  );

  function incrementCountForConversation(conversationId: number) {
    setMap((oldState) => {
      const newState = { ...oldState };

      if (newState[conversationId] !== undefined) {
        newState[conversationId]++;
      } else {
        newState[conversationId] = 1;
      }

      return newState;
    });
  }

  const totalCount = React.useMemo(() => {
    return Object.values(map).reduce((acc, curr) => acc + curr, 0);
  }, [map]);

  return (
    <UnreadMessagesContext.Provider
      value={{ getCount, setCurrentConversationId, totalCount }}
    >
      {props.children}
    </UnreadMessagesContext.Provider>
  );
}
