import React from "react";
import {
  defaultInboxFilters,
  inboxFilterOptions,
  inboxGroupOptions,
  inboxSortOptions
} from "@/constants";
import useInbox from "@/hooks/useInbox";
import { InboxFilters } from "@/types";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import useTranslations from "@/hooks/useTranslations";
import { TeriaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
interface Props {
  onClose(): void;
}
export default function FilterModal({ onClose }: Props) {
  const { state, dispatch, setSettings, settings } = useInbox();
  const filters = settings.filters || defaultInboxFilters;
  const sortBy = settings.sortBy || "newest";
  const groupBy = settings.groupBy || "none";
  const translation = useTranslations();

  const filterCategories = Object.keys(inboxFilterOptions);

  function toggleFilter(category: string, value: string) {
    const filter = filters[category as keyof InboxFilters] as string[];
    const index = filter.indexOf(value);
    if (index >= 0) {
      filter.splice(index, 1);
    } else {
      filter.push(value);
    }
    setSettings({
      ...settings,
      filters: {
        ...filters,
        [category]: filter
      }
    });
  }

  function renderFilterCategory(category: string, i: number) {
    const filterCategoryOptions =
      inboxFilterOptions[category as keyof InboxFilters];
    const filterCategoryValues = filters[
      category as keyof InboxFilters
    ] as string[];

    if (category === "agent" || category === "team") {
      return;
    }

    const options = filterCategoryOptions.values as Record<string, string>;
    return (
      <div key={i} className="flex flex-col">
        <div className="font-semibold mb-2">
          {translation.key(filterCategoryOptions.label.toLowerCase())}
        </div>
        <div className="flex flex-col justify-center gap-1">
          {Object.keys(options).map((v, i) => (
            <div key={i} className="flex items-center">
              <input
                type="checkbox"
                onChange={() => toggleFilter(category, v)}
                checked={filterCategoryValues.includes(v)}
              />
              <div className="ml-2">
                {translation.key(options[v].toLowerCase())}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <Modal show={true} maxWidth="max-w-fit">
      <Modal.Header
        title={translation.key("filters")}
        onClose={() => onClose()}
      />
      <Modal.Body>
        <div className="flex flex-wrap gap-4 justify-between m-4">
          <div>
            <div className="flex gap-4 justify-center border-r pr-4">
              {filterCategories.map(renderFilterCategory)}
            </div>
          </div>
          <div className="flex">
            <div className="flex flex-col gap-1 border-r pr-4">
              <div className="font-semibold mb-2">
                {translation.key("sort_by")}
              </div>
              {inboxSortOptions.map((option, i) => (
                <div key={i} className="flex items-center">
                  <input
                    name="sort"
                    type="radio"
                    onChange={() => {
                      setSettings({
                        ...settings,
                        sortBy: option.value
                      });
                    }}
                    checked={sortBy === option.value}
                  />
                  <div className="ml-2">
                    {translation.key(option.label.toLowerCase())}
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-1 pl-4">
              <div className="font-semibold mb-2">
                {translation.key("group_by")}
              </div>
              {inboxGroupOptions.map((option, i) => (
                <div key={i} className="flex items-center">
                  <input
                    name="group"
                    type="radio"
                    onChange={() => {
                      setSettings({
                        ...settings,
                        groupBy: option.value
                      });
                    }}
                    checked={groupBy === option.value}
                  />
                  <div className="ml-2">
                    {translation.key(option.label.toLowerCase())}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <TeriaryBlueButton
          onClick={() => {
            setSettings({
              filters: JSON.parse(JSON.stringify(defaultInboxFilters)),
              sortBy: "newest",
              groupBy: "none",
              selectedTab: settings.selectedTab,
              searchQuery: settings.searchQuery
            });
          }}
          label={translation.key("reset_filters")}
        />
      </Modal.Footer>
    </Modal>
  );
}
