import useTranslations from "@/hooks/useTranslations";
import IconSearch from "@/icons/IconSearch";
import { Conversation, Agent, TeamListItem, SkillListItem } from "@/types";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useCallback } from "react";
import IconClose from "@velaro/velaro-shared/src/icons/IconClose";
import useApi from "@/hooks/useApi";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import AgentListItem from "./AgentListItem";
import ConfirmAssignmentModal from "./ConfirmAssignmentModal";
import { set } from "lodash";
import { on } from "events";
import ListItem from "./ListItem";

interface Props {
  conversation: Conversation;
  onClose(): void;
}

export default function ChatAssignmentModal(props: Props) {
  const [searchFilter, setSearchFilter] = React.useState("");
  const translation = useTranslations();
  const [selectionType, setSelectionType] = React.useState(
    "Agent" as "Agent" | "Team" | "Skill"
  );
  const [openConfirmAssignmentModal, setOpenConfirmAssignmentModal] =
    React.useState(false);
  const [agents, setAgents] = React.useState<Agent[]>([]);
  const [teams, setTeams] = React.useState<TeamListItem[]>([]);
  const [skills, setSkills] = React.useState<SkillListItem[]>([]);
  const [selectedAgent, setSelectedAgent] = React.useState<Agent | null>();
  const [selectedTeam, setSelectedTeam] = React.useState<TeamListItem | null>();
  const [selectedSkill, setSelectedSkill] =
    React.useState<SkillListItem | null>();

  const api = useApi();
  const toastAlert = useToastAlert();

  const setAgentInfo = useCallback((agent: Agent) => {
    setSelectedAgent(agent);
    setSelectedTeam(null);
    setSelectedSkill(null);
  }, []);

  const setTeamInfo = useCallback((team: TeamListItem) => {
    setSelectedTeam(team);
    setSelectedAgent(null);
    setSelectedSkill(null);
  }, []);

  const setSkillInfo = useCallback((skill: SkillListItem) => {
    setSelectedSkill(skill);
    setSelectedAgent(null);
    setSelectedTeam(null);
  }, []);

  React.useEffect(() => {
    async function fetchAgents() {
      const response = await api.messaging.get(
        `Users/List/${props.conversation.groupId}`
      );
      if (!response.ok) {
        toastAlert.displayToast(Severity.Error, "Failed to fetch agents");
      }
      setAgents(await response.json());
    }

    async function fetchTeams() {
      const response = await api.messaging.get("Teams/List");
      if (!response.ok) {
        toastAlert.displayToast(Severity.Error, "Failed to fetch teams");
      }
      setTeams(await response.json());
    }

    async function fetchSkills() {
      const response = await api.messaging.get("Skills/List");
      if (!response.ok) {
        toastAlert.displayToast(Severity.Error, "Failed to fetch teams");
      }
      setSkills(await response.json());
    }

    fetchAgents();
    fetchTeams();
    fetchSkills();
  }, [api.messaging, props.conversation.groupId, toastAlert]);

  function setTypeOfAssignment(type: string) {
    setSelectionType(type as "Agent" | "Team" | "Skill");
    switch (type) {
      case "Agent":
        break;
      case "Team":
        break;
      case "Skill":
        break;
    }
  }

  function activateAgentAssignmentModal() {
    if (!selectedAgent && !selectedTeam && !selectedSkill) {
      return;
    } else {
      setOpenConfirmAssignmentModal(true);
    }
  }

  return (
    <Modal show={true} maxWidth="max-w-[400px]">
      <Modal.Body>
        <div className="flex p-6 items-center">
          <div className="text-xl font-semibold">Chat Re-Assignment</div>
          <button
            className="ml-auto h-10 w-6 text-slate-gray-500 justify-end items-center"
            onClick={() => props.onClose()}
          >
            <IconClose />
          </button>
        </div>
        <div className="flex">
          <div className="mx-6 mb-6 grow shrink basis-0 p-3 bg-white rounded border border-zinc-200 justify-between items-center flex">
            <input
              className="text-base font-normal font-['Rubik'] leading-normal border-none border-0 outline-none w-full"
              placeholder={translation.key("search_here")}
              onChange={(e) => setSearchFilter(e.target.value)}
            />
            <div className="justify-center items-center flex">
              <div className="w-6 h-6 relative text-slate-gray-200">
                <IconSearch />
              </div>
            </div>
          </div>
        </div>
        <div className="flex  w-[352px] mx-6 h-9 items-center text-slate-gray-300">
          <ListHeader
            type="Agent"
            currentSelection={selectionType}
            onClick={() => setTypeOfAssignment("Agent")}
          />
          <ListHeader
            type="Team"
            currentSelection={selectionType}
            onClick={() => setTypeOfAssignment("Team")}
          />
          <ListHeader
            type="Skill"
            currentSelection={selectionType}
            onClick={() => setTypeOfAssignment("Skill")}
          />
        </div>
        <div className="flex my-6 mx-6 w-[352px] max-h-[136px]">
          <div className="flex flex-col gap-2 overflow-y-auto overflow-x-hidden">
            {selectionType === "Agent" && (
              <>
                {agents
                  .filter((x) => x.id !== props.conversation.assignedUserId)
                  .filter((x) =>
                    x.displayName.toLowerCase().includes(searchFilter)
                  )
                  .map((agent, key) => (
                    <AgentListItem
                      agent={agent}
                      showAvailability={true}
                      notSelectable={false}
                      setAgentInfo={setAgentInfo}
                      selected={selectedAgent?.id === agent.id}
                      key={key}
                    />
                  ))}
              </>
            )}
            {selectionType === "Team" && (
              <>
                {teams
                  .filter((x) => x.name.toLowerCase().includes(searchFilter))
                  .map((team, key) => (
                    <ListItem
                      team={team}
                      notSelectable={false}
                      setTeamInfo={setTeamInfo}
                      selected={selectedTeam?.id === team.id}
                      key={key}
                    />
                  ))}
              </>
            )}
            {selectionType === "Skill" && (
              <>
                {skills
                  .filter((x) => x.name.toLowerCase().includes(searchFilter))
                  .map((skill, key) => (
                    <ListItem
                      skill={skill}
                      notSelectable={false}
                      setSkillInfo={setSkillInfo}
                      selected={selectedSkill?.id === skill.id}
                      key={key}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
        <div>
          <button
            className="h-10 rounded-md mx-6 mb-6 text-white bg-cornflower-blue-500 text-center items-center w-[352px]"
            onClick={() => activateAgentAssignmentModal()}
          >
            Assign
          </button>
        </div>
        {openConfirmAssignmentModal && (
          <ConfirmAssignmentModal
            conversation={props.conversation}
            agent={selectedAgent}
            team={selectedTeam}
            skill={selectedSkill}
            onClose={() => setOpenConfirmAssignmentModal(false)}
            closeReassignModal={() => props.onClose()}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}

function ListHeader({
  type,
  currentSelection,
  onClick
}: {
  type: string;
  currentSelection: string;
  onClick?: () => void;
}) {
  return (
    <div className="w-1/3">
      <button
        id={type}
        className={
          "w-full pb-2 " +
          (currentSelection == type
            ? "border-b-2 border-cornflower-blue-500 text-cornflower-blue-500"
            : "border-b border-slate-gray-300")
        }
        onClick={onClick}
      >
        {type}
      </button>
    </div>
  );
}
