import { Agent, Conversation, SkillListItem, TeamListItem } from "@/types";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useCallback } from "react";
import AgentListItem from "./AgentListItem";
import IconClose from "@velaro/velaro-shared/src/icons/IconClose";
import useApi from "@/hooks/useApi";
import useScreenWidth from "@/hooks/useScreenWidth";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import useTheme from "@/hooks/useTheme";
import useConversationNotes from "@/hooks/useConversationNotes";
import ReassignmentNote from "./Notes/ReassignmentNote";

interface Props {
  conversationId: number;
  onClose(): void;
}

export default function AddNoteModal(props: Props) {
  const api = useApi();
  const screenWidth = useScreenWidth();
  const toastAlert = useToastAlert();
  const [note, setNote] = React.useState("");
  const conversationNotes = useConversationNotes(props.conversationId);
  const { theme, setTheme } = useTheme();

  const setReassignmentInfo = useCallback((text: string) => {
    setNote(text);
  }, []);

  function addNote() {
    conversationNotes.add(note);
    props.onClose();
  }

  return (
    <Modal show={true} maxWidth="max-w-[640px]">
      <Modal.Body>
        <div>
          <div className="flex p-6 items-center">
            <div className="text-lg font-medium">Add Note</div>
            <button
              className="ml-auto h-10 w-10 text-slate-gray-500"
              onClick={() => props.onClose()}
            >
              <IconClose />
            </button>
          </div>
          <div>
            <div className="flex flex-col text-sm leading-[21px] px-6">
              <ReassignmentNote
                conversationId={props.conversationId}
                setReassignmentInfo={setReassignmentInfo}
              />
              <div className="flex flex-row gap-4 py-6 justify-end">
                <button
                  className="w-[120px] h-10 border-[1.5px] rounded-md border-cornflower-blue-500 bg-white text-cornflower-blue-500"
                  onClick={() => props.onClose()}
                >
                  Cancel
                </button>
                <button
                  className="w-[120px] h-10 rounded-md border-cornflower-blue-500 bg-cornflower-blue-500 text-white"
                  onClick={() => addNote()}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
