import useTranslations from "@/hooks/useTranslations";
import IconSearch from "@/icons/IconSearch";
import { Conversation, FormList, FormListItem } from "@/types";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useCallback } from "react";
import IconClose from "@velaro/velaro-shared/src/icons/IconClose";
import useApi from "@/hooks/useApi";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import ListItem from "./ListItem";
import IconFormstackFullLogo from "@/icons/IconFormstackFullLogo";
import LoadingSpinner from "@velaro/velaro-shared/src/components/LoadingSpinner";

interface Props {
  conversation: Conversation;
  onClose(): void;
}

export default function FormstackModal(props: Props) {
  const [searchFilter, setSearchFilter] = React.useState("");
  const translation = useTranslations();
  const [forms, setForms] = React.useState<FormList>();
  const [selectedForm, setSelectedForm] = React.useState<FormListItem>();
  const [formstackConfigFound, setFormstackConfigFound] = React.useState(false);
  const [doFormsExist, setDoFormsExist] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [formPreview, setFormPreview] = React.useState<FormListItem | null>();
  const api = useApi();
  const toastAlert = useToastAlert();

  const setFormInfo = useCallback((form: FormListItem) => {
    setSelectedForm(form);
  }, []);

  React.useEffect(() => {
    async function fetchForms() {
      console.log("Fetching forms");
      const response = await api.messaging.get(`Formstack/Integrations/List`);
      setForms(await response.json());
      setFormstackConfigFound(true);
      setIsLoading(false);
    }
    fetchForms();
  }, [api.messaging]);

  async function sendForm(
    selectedForm: FormListItem | undefined,
    conversationId: number
  ) {
    if (selectedForm) {
      console.log("Sending form", selectedForm);
      const response = await api.messaging.post("Formstack/Integrations/Form", {
        conversationId,
        formName: selectedForm.name,
        url: selectedForm.url
      });
      if (!response.ok) {
        toastAlert.displayToast(Severity.Error, "Failed to send form");
      }
      props.onClose();
    }
  }

  return (
    <div>
      {formstackConfigFound && !isLoading && (
        <Modal show={true} maxWidth="max-w-[400px]">
          <Modal.Body>
            <div className="flex p-6 items-center">
              <IconFormstackFullLogo />
              <button
                className="ml-auto h-10 w-6 text-slate-gray-500 justify-end items-center"
                onClick={() => props.onClose()}
              >
                <IconClose />
              </button>
            </div>
            <div className="flex">
              <div className="mx-6 grow shrink basis-0 p-3 bg-white rounded border border-zinc-200 justify-between items-center flex">
                <input
                  className="text-base font-normal font-['Rubik'] leading-normal border-none border-0 outline-none w-full"
                  placeholder={translation.key("search_here")}
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
                <div className="justify-center items-center flex">
                  <div className="w-6 h-6 relative text-slate-gray-200">
                    <IconSearch />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex my-6 mx-6 w-[352px] max-h-[136px]">
              <div className="flex flex-col gap-2 overflow-y-auto overflow-x-hidden">
                {forms && (
                  <>
                    {forms.forms != null && (
                      <>
                        {forms.forms
                          .filter((x) =>
                            x.name.toLowerCase().includes(searchFilter)
                          )
                          .map((form, key) => (
                            <ListItem
                              form={form}
                              notSelectable={false}
                              selected={selectedForm?.id == form.id}
                              setFormInfo={setFormInfo}
                              key={key}
                              setPreview={setFormPreview}
                            />
                          ))}
                      </>
                    )}
                    {forms.forms == null && (
                      <div>
                        <div>
                          <div className="flex flex-col my-4"></div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div>
              <button
                className="h-10 rounded-md mx-6 mb-6 text-white bg-cornflower-blue-500 text-center items-center w-[352px]"
                onClick={() => sendForm(selectedForm, props.conversation.id)}
              >
                Send
              </button>
            </div>
          </Modal.Body>
          {formPreview != null && (
            <div className="absolute top-[-300px] right-[-475px] h-[984px] rounded-xl w-[450px] gap-2 bg-white overflow-y-auto overflow-x-hidden">
              <iframe
                className="w-full h-full m-0 p-0 border-none transform rounded-2xl scale-95"
                src={formPreview?.url}
                title="Formstack Form"
                scrolling="no"
              ></iframe>
            </div>
          )}
        </Modal>
      )}
      {!formstackConfigFound && !isLoading && (
        <Modal show={true} maxWidth="max-w-[400px]">
          <Modal.Body>
            <div className="flex p-6 items-center">
              <IconFormstackFullLogo />
              <button
                className="ml-auto h-10 w-6 text-slate-gray-500 justify-end items-center"
                onClick={() => props.onClose()}
              >
                <IconClose />
              </button>
            </div>
            <div className="flex my-6 mx-6 w-[352px] max-h-[136px]">
              <div className="flex flex-col gap-2 overflow-y-auto overflow-x-hidden">
                Please configure Formstack integration first in the Admin
                Portal.
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {isLoading && (
        <Modal show={true} maxWidth="max-w-[400px]">
          <Modal.Body>
            <div className="flex p-6 items-center">
              <IconFormstackFullLogo />
              <button
                className="ml-auto h-10 w-6 text-slate-gray-500 justify-end items-center"
                onClick={() => props.onClose()}
              >
                <IconClose />
              </button>
            </div>
            <div className="flex my-6 mx-6 w-[352px] max-h-[136px] justify-center">
              <LoadingSpinner />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
