import { Tag } from "@/types";
import { useEffect, useState } from "react";
import useApi from "./useApi";
import useSubscribe from "./useSubscribe";

export default function useConversationTags(conversationId: number) {
  const [all, setAll] = useState<Tag[]>([]);
  const [selected, setSelected] = useState<Tag[]>([]);
  const api = useApi();
  const subscribe = useSubscribe();

  useEffect(() => {
    async function fetchAll() {
      const resp = await api.messaging.get("ConversationTags/All");
      setAll(await resp.json());
    }

    fetchAll();
  }, [api.messaging]);

  useEffect(() => {
    async function fetchSelected() {
      const resp = await api.messaging.get(
        `ConversationTags/Selected?conversationId=${conversationId}`
      );
      setSelected(await resp.json());
    }

    fetchSelected();
  }, [api.messaging, conversationId]);

  function save(tags: Tag[]) {
    setSelected(tags);

    api.messaging.post("ConversationTags/Associate", {
      conversationId,
      tags: tags.map((t) => t.name.trim())
    });
  }

  function remove(tag: Tag) {
    setSelected((selected) => selected.filter((t) => t.name !== tag.name));

    api.messaging.post("ConversationTags/Remove", {
      conversationId,
      tag: tag.name.trim()
    });
  }

  useEffect(
    () =>
      subscribe("conversation-tags-updated", (message) => {
        setAll(message.allTags);

        if (message.conversationId === conversationId) {
          setSelected(message.selectedTags);
        }
      }),
    [conversationId, subscribe]
  );

  return { all, selected, save, remove };
}
