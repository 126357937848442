import { Tag } from "@/types";
import React, { useState } from "react";
import TagModal from "./TagModal";
import RemovableBaseBadge from "@velaro/velaro-shared/src/components/Badges/RemovableBaseBadge";
import useTranslations from "@/hooks/useTranslations";

interface Props {
  label: string;
  labelSize?: "sm" | "md";
  onSave: (tags: Tag[]) => void;
  onRemove: (tag: Tag) => void;
  allTags: Tag[];
  selectedTags: Tag[];
  badgeSize?: "sm" | "md";
}

export default function Tags(props: Props) {
  const [showModal, setShowModal] = useState(false);
  const translation = useTranslations();

  const labelSize = props.labelSize === "sm" ? "text-sm" : "text-base";

  return (
    <>
      <div className="dark:text-white">
        <div className={`${labelSize} mb-2 font-medium`}>{props.label}</div>
        <div className="flex flex-wrap items-center">
          {props.selectedTags.map((tag) => (
            <RemovableBaseBadge
              key={tag.id}
              label={tag.name}
              onClose={() => props.onRemove(tag)}
              size={props.badgeSize}
            />
          ))}
          <button
            type="button"
            className="h-6 flex items-center rounded px-2 mr-1 text-sm hover:bg-slate-gray-100 hover:cursor-pointer hover:text-cornflower-blue-500"
            onClick={() => setShowModal(true)}
          >
            <i className="fa fa-plus"></i>
            <span className="ml-1 text-sm">{translation.key("add_tag")}</span>
          </button>
        </div>
      </div>
      {showModal && (
        <TagModal
          headerText={props.label}
          allTags={props.allTags}
          selectedTags={props.selectedTags}
          onClose={() => setShowModal(false)}
          onSave={props.onSave}
        />
      )}
    </>
  );
}
