import Modal from "@velaro/velaro-shared/src/components/Modal";
import { Tag } from "@/types";
import React, { useEffect, useMemo, useState } from "react";

interface Props {
  headerText: string;
  allTags: Tag[];
  selectedTags: Tag[];
  onClose(): void;
  onSave(tags: Tag[]): void;
}

export default function TagModal(props: Props) {
  const [allTags, setAllTags] = React.useState<Tag[]>([]);
  const [searchOrCreateText, setSearchOrCreateText] = React.useState("");
  const [selectedTags, setSelectedTags] = React.useState<Tag[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setAllTags(props.allTags);
  }, [props.allTags]);

  useEffect(() => {
    setSelectedTags(props.selectedTags);
  }, [props.selectedTags]);

  const unselectedTags = useMemo(() => {
    return allTags.filter(
      (tag) => !selectedTags.some((selectedTag) => selectedTag.id === tag.id)
    );
  }, [allTags, selectedTags]);

  const unselectedFilteredTags = useMemo(() => {
    if (searchOrCreateText === "") {
      return unselectedTags;
    } else {
      return unselectedTags.filter((tag) =>
        tag.name.toLowerCase().includes(searchOrCreateText.toLowerCase())
      );
    }
  }, [searchOrCreateText, unselectedTags]);

  const showCreateButton = useMemo(() => {
    if (searchOrCreateText.length === 0) {
      return false;
    }

    const hasExactMatch =
      unselectedTags.some((tag) => {
        return tag.name.toLowerCase() === searchOrCreateText.toLowerCase();
      }) ||
      selectedTags.some((tag) => {
        return tag.name.toLowerCase() === searchOrCreateText.toLowerCase();
      });

    if (hasExactMatch) {
      return false;
    }

    return true;
  }, [searchOrCreateText, unselectedTags]);

  const isCreateButtonSelected = useMemo(() => {
    return selectedIndex === unselectedFilteredTags.length;
  }, [selectedIndex, unselectedFilteredTags.length]);

  function addSelectedTag(tag: Tag): void {
    if (tag.id === 0) {
      tag.id = allTags.length + 1;
    }
    setSelectedTags([...selectedTags, tag]);
    setAllTags([...allTags, tag]);
    setSearchOrCreateText("");
  }

  function removeSelectedTag(index: number): void {
    setSelectedTags(selectedTags.filter((_, i) => i !== index));
  }

  function moveSelectionUp() {
    if (selectedIndex === 0) {
      return;
    }

    setSelectedIndex((x) => x - 1);
  }

  function moveSelectionDown() {
    if (selectedIndex >= unselectedFilteredTags.length) {
      return;
    }

    setSelectedIndex((x) => x + 1);
  }

  function doSelection() {
    if (!showCreateButton) return;
    if (isCreateButtonSelected) {
      addSelectedTag({
        id: 0,
        name: searchOrCreateText
      });
    } else {
      addSelectedTag(unselectedFilteredTags[selectedIndex]);
    }
  }

  return (
    <Modal show={true}>
      <Modal.Header title={props.headerText} onClose={() => props.onClose()} />
      <Modal.Body>
        <div className="flex border-t border-b p-2 text-stone-600 dark:text-white dark:border-stone-200">
          <div className="pt-1 mr-2">
            <i className="fa fa-search"></i>
          </div>
          <div className="flex flex-wrap overflow-hidden">
            {selectedTags.map((tag, index) => (
              <div
                key={index}
                className="h-7 flex items-center border rounded px-2 mr-1 mb-1 text-sm max-w-full"
              >
                <i className="flex-0 fa fa-tag mr-1"></i>
                <span className="flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis">
                  {tag.name}
                </span>
                <button
                  className="flex-0 ml-1"
                  onClick={() => removeSelectedTag(index)}
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
            ))}
            <input
              autoFocus
              type="text"
              className="outline-none text-sm h-7 w-64 mb-1 dark:bg-stone-500"
              placeholder="Type to search or create a new tag..."
              value={searchOrCreateText}
              onChange={(e) => setSearchOrCreateText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "ArrowUp") {
                  moveSelectionUp();
                } else if (e.key === "ArrowDown") {
                  moveSelectionDown();
                } else if (e.key === "Enter") {
                  doSelection();
                } else if (e.key === "Backspace") {
                  if (searchOrCreateText.length === 0) {
                    removeSelectedTag(selectedTags.length - 1);
                  }
                } else {
                  setSelectedIndex(0);
                }
              }}
            />
          </div>
        </div>
        <div className="overflow-y-auto" style={{ height: 280 }}>
          <div>
            {unselectedFilteredTags.map((tag, index) => (
              <button
                className={
                  selectedIndex === index
                    ? selectedOptionStyle
                    : unselectedOptionStyle
                }
                key={tag.id}
                onClick={() => addSelectedTag(tag)}
                onMouseEnter={() => setSelectedIndex(index)}
              >
                {tag.name}
              </button>
            ))}
          </div>
          {showCreateButton && (
            <button
              className={
                isCreateButtonSelected
                  ? selectedOptionStyle
                  : unselectedOptionStyle
              }
              onClick={() => {
                addSelectedTag({ id: 0, name: searchOrCreateText });
                setSearchOrCreateText("");
              }}
              onMouseEnter={() =>
                setSelectedIndex(unselectedFilteredTags.length)
              }
            >
              <i className="fa fa-tag mr-2"></i>
              <span>Create new tag: &apos;{searchOrCreateText}&apos;</span>
            </button>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center p-3">
          <div className="ml-auto">
            <button
              className="btn btn-green"
              onClick={() => {
                props.onSave(
                  selectedTags.sort((a, b) => {
                    return a.name < b.name ? -1 : 1;
                  })
                );
                props.onClose();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

const unselectedOptionStyle =
  "font-semibold text-sm p-2 text-left block w-full";

const selectedOptionStyle = `${unselectedOptionStyle} bg-cornflower-blue-600 text-white`;
