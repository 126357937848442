import React from "react";
import Calendar from "@velaro/velaro-shared/src/svg/stroke-icons/calendar.svg?react";
import format from "date-fns/format";
import { TimelineEvent } from "../types";

interface Props {
  events: TimelineEvent[];
}

export default function EventTimeline(props: Props) {
  function getEventMessage(event: TimelineEvent) {
    switch (event.eventType) {
      case "ContactCreated":
        return "Contact added to the system.";
      case "ConversationCreated":
        return "Conversation started.";
      case "ConversationResolved":
        return (
          "Conversation resolved by " + event.customDataBlob.ResolvedBy + "."
        );
      default:
        return "Unknown event.";
    }
  }

  if (props.events.length === 0) {
    return (
      <div className="bg-white rounded-lg p-4 mb-4">
        <div className="text-sm font-semibold mb-4">Event Timeline</div>
        <div className="w-full flex flex-col justify-center items-center">
          <div className="text-slate-400 text-xs">There is no event done.</div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg p-4 mb-4">
      <div className="text-sm font-semibold mb-4">Event Timeline</div>
      {props.events.map((event, index) => (
        <div key={index} className="flex my-4">
          <div className=" flex flex-col mr-3 items-center gap-1">
            <Calendar className="text-slate-500" />
            <div className="border-l-2 border-slate-500 flex-grow"></div>
          </div>
          <div>
            <div className="text-slate-500 text-xs mb-1">
              {format(new Date(event.dateCreated), "M/d/yyyy")}
            </div>
            <div className="text-sm mb-1">{getEventMessage(event)}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
