import * as React from "react";
import useMessages from "../../hooks/useMessages";
import MessageGroupItem from "./MessageGroupItem";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";
import useBottomScroll from "../../hooks/useBottomScroll";
import PastConversations from "./PastConversations";
import { Conversation } from "@/types";
import usePastConversations from "@/hooks/usePastConversations";
import ConversationPanelHeader from "./ConversationPanelHeader";
import useApi from "@/hooks/useApi";
import useSubscribe from "@/hooks/useSubscribe";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import MessageForm from "./MessageForm";
import useTranslations from "@/hooks/useTranslations";
import { useParams } from "react-router-dom";
import useProfile from "@/hooks/useProfile";

interface Props {
  contactId: number;
  conversation: Conversation;
  updateTopic(topic: string): void;
}

export default function MessageList(props: Props) {
  const { pastConversations, updatePastConversation } = usePastConversations(
    props.contactId
  );

  const messageContainer = React.useRef<HTMLDivElement>(null);

  const { messages, groupedMessages, loadMoreMessages } = useMessages(
    props.conversation.id
  );

  const { currentlyAtBottom, scrollToBottom } =
    useBottomScroll(messageContainer);
  const api = useApi();
  const toastAlert = useToastAlert();
  const translation = useTranslations();
  const { profile } = useProfile();

  const setTopic = React.useCallback(
    (topic: string, convoId: number) => {
      async function handleSubmit() {
        const resp = await api.messaging.put(`Conversations/${convoId}`, {
          topic
        });

        if (resp.ok) {
          props.updateTopic(topic);
        } else {
          toastAlert.displayToast(
            Severity.Error,
            "An unexpected error occurred"
          );
        }
      }
      handleSubmit();
    },
    [api.messaging, props, toastAlert]
  );
  const [expanded, setExpanded] = React.useState(true);
  const subscribe = useSubscribe();
  const [isTyping, setsIsTyping] = React.useState(false);
  const [elipses, setElipses] = React.useState("...");
  const [typeEllipsis, setTypeEllipsis] = React.useState("");
  const { conversationId } = useParams<{ conversationId: string }>();

  // todo: infinite scroll is pretty much broken now that we're loading
  // past conversations on top of the current conversation. have to find
  // a solution to that problem.

  useInfiniteScroll(messageContainer, messages, loadMoreMessages);

  // Scroll to the bottom when the messages array changes as long
  // as the container was already scrolled to the bottom.

  React.useEffect(() => {
    if (conversationId != props.conversation.id.toString()) {
      setExpanded(false);
    } else {
      setExpanded(true);
      scrollToBottom();
    }
  }, [conversationId, props.conversation.id, scrollToBottom]);

  React.useLayoutEffect(() => {
    if (currentlyAtBottom) {
      scrollToBottom();
    }
  }, [messages, scrollToBottom, currentlyAtBottom, pastConversations.length]);

  // Force scrollbar to the bottom if the conversationId changes.

  React.useLayoutEffect(() => {
    scrollToBottom();
  }, [props.conversation.id, scrollToBottom]);

  React.useEffect(
    () =>
      subscribe("is-typing", (data) => {
        if (Number(data) === props.conversation.id) {
          setsIsTyping(true);
          const timer = setTimeout(() => {
            setsIsTyping(false);
          }, 3000);
          return () => clearTimeout(timer);
        }
      }),
    [props.conversation.id, subscribe]
  );

  //Simulate typing an ellipsis to be used when visitor is typing
  React.useEffect(() => {
    if (!isTyping) {
      return;
    }
    const timeout = setTimeout(() => {
      setTypeEllipsis(elipses.slice(0, typeEllipsis.length + 1));
      if (typeEllipsis.length === 3) {
        setTypeEllipsis("");
      }
    }, 400);
    return () => clearTimeout(timeout);
  }, [elipses, isTyping, typeEllipsis]);

  return (
    <div
      ref={messageContainer}
      className="grouped-messages h-full flex flex-col overflow-y-auto p-6 gap-2 bg-slate-gray-50"
    >
      <div className="mt-auto">
        <PastConversations
          conversations={pastConversations}
          updatePastConvo={updatePastConversation}
        />
        {props.conversation.status !== "Resolved" && (
          <div className="bg-white dark:bg-stone-300 gap-2 rounded-lg border border-slate-gray-50 mt-6">
            <ConversationPanelHeader
              conversation={props.conversation}
              expanded={expanded}
              onClick={() => setExpanded(!expanded)}
              hideAngle
              setTopic={(topic) => setTopic(topic, props.conversation.id)}
              messageCount={messages.filter((x) => x.type !== "system").length}
            />
            {expanded && (
              <div className="p-4">
                {groupedMessages.map((item, index) => (
                  <MessageGroupItem
                    key={index}
                    contactId={props.contactId}
                    item={item}
                    conversationSource={props.conversation.source}
                    onImageLoad={() => {
                      if (currentlyAtBottom) {
                        scrollToBottom();
                      }
                    }}
                  />
                ))}
                {isTyping && (
                  <div>
                    {props.conversation.contact.displayName +
                      " " +
                      translation.key("is_typing") +
                      typeEllipsis}
                  </div>
                )}
                <div className="message-form-wrapper">
                  {props.conversation.assignedUserId == profile.id && (
                    <MessageForm conversation={props.conversation} />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
