import useToastAlert from "@/hooks/useToastAlert";
import { PrimaryBlueButton } from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import Modal from "@velaro/velaro-shared/src/components/Modal";
import React, { useState } from "react";
import { ContactKPIItem } from "./types";

interface Props {
  onClose: () => void;
  contactKPIs: ContactKPIItem[];
  setSelectedKPIs: (kpi: string[]) => void;
  selectedKPIs: string[];
}

export default function AddKPIModal(props: Props) {
  const [saving, setSaving] = useState(false);
  const [workingKPIs, setWorkingKPIs] = useState<string[]>(props.selectedKPIs);
  const [error, setError] = useState("");

  function handleSubmit() {
    setSaving(true);
    if (workingKPIs.length < 2 || workingKPIs.length > 4) {
      setError("Please select between 2 and 4 KPIs");
      setSaving(false);
      return;
    }
    props.setSelectedKPIs(workingKPIs);
    setSaving(false);
    props.onClose();
  }

  function renderKpiCheckbox(kpi: ContactKPIItem) {
    return (
      <div key={kpi.id} className="flex items-center mb-4">
        <input
          type="checkbox"
          id={kpi.id}
          name={kpi.label}
          checked={!!workingKPIs.includes(kpi.id)}
          onChange={(e) => {
            if (e.target.checked) {
              setWorkingKPIs([...workingKPIs, kpi.id]);
            } else {
              setWorkingKPIs(workingKPIs.filter((x) => x !== kpi.id));
            }
          }}
          className="mr-2"
        />
        <label htmlFor={kpi.id}>{kpi.label}</label>
      </div>
    );
  }

  return (
    <Modal show={true} maxWidth="max-w-[600px]">
      <Modal.Header title="KPI" onClose={() => props.onClose()} />
      <Modal.Body>
        <div className="p-6">
          <div className="mb-4">
            Choose a minimum of 2 and up to 4 Key Performance Indicators (KPIs)
          </div>
          <div className="flex">
            <div className="w-1/2">
              <div className="mb-4">Contacts</div>
              {/* only display first contactKPI */}
              {props.contactKPIs.slice(0, 1).map(renderKpiCheckbox)}
            </div>
            <div className="w-1/2">
              <div className="mb-4">Conversations</div>
              {props.contactKPIs.slice(1).map(renderKpiCheckbox)}
            </div>
          </div>
          {error && <div className="text-red-500 mt-4">{error}</div>}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex items-center p-6">
          <div className="ml-auto flex">
            <PrimaryBlueButton
              disabled={saving}
              label="Save"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
