import useTranslations from "@/hooks/useTranslations";
import React, { useMemo, useRef, useState } from "react";
import ReactDomServer from "react-dom/server";
import UploadButton from "../UploadButton";
import IconEmoji from "@/icons/IconEmoji";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import useScreenWidth from "@/hooks/useScreenWidth";
import useTheme from "@/hooks/useTheme";
import ReactQuill from "react-quill";
import "@/themes/quill.snow.css";
import IconBoldButton from "@velaro/velaro-shared/src/icons/IconBoldButton";
import IconItalicsButton from "@velaro/velaro-shared/src/icons/IconItalicsButton";
import IconUnderlineButton from "@velaro/velaro-shared/src/icons/IconUnderlineButton";
import IconStrikethroughButton from "@velaro/velaro-shared/src/icons/IconStrikethroughButton";
import IconLinkButton from "@velaro/velaro-shared/src/icons/IconLinkButton";
import IconListButton from "@velaro/velaro-shared/src/icons/IconListButton";
import IconAIButton from "@velaro/velaro-shared/src/icons/IconAICursor";
import useApi from "@/hooks/useApi";
import Tooltip from "@velaro/velaro-shared/src/components/Tooltip";
import { set } from "lodash";
import useSubscriptions from "@/hooks/useSubscriptions";

interface Props {
  conversationId?: number;
  setReassignmentInfo(note: string): void;
}

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike", "link", { list: "bullet" }]
  ]
};

const icons = ReactQuill.Quill.import("ui/icons");
const boldIcon = ReactDomServer.renderToStaticMarkup(<IconBoldButton />);
const italicsIcon = ReactDomServer.renderToStaticMarkup(<IconItalicsButton />);
const underlineIcon = ReactDomServer.renderToStaticMarkup(
  <IconUnderlineButton />
);
const strikethroughIcon = ReactDomServer.renderToStaticMarkup(
  <IconStrikethroughButton />
);
const linkIcon = ReactDomServer.renderToStaticMarkup(<IconLinkButton />);
const listIcon = ReactDomServer.renderToStaticMarkup(<IconListButton />);
icons["bold"] = boldIcon;
icons["italic"] = italicsIcon;
icons["underline"] = underlineIcon;
icons["strike"] = strikethroughIcon;
icons["link"] = linkIcon;
icons["list"] = listIcon;

export default function ReassignmentNote(props: Props) {
  const translation = useTranslations();
  const [attachments, setAttachments] = React.useState<File[]>([]);
  const [showEmojiPicker, setShowEmojiPicker] = React.useState(false);
  const [showAINotice, setShowAINotice] = React.useState(false);
  const screenWidth = useScreenWidth();
  const { theme, setTheme } = useTheme();
  const [value, setValue] = useState("");
  const api = useApi();
  const [loadingAiSummary, setLoadingAiSummary] = useState(false);
  const subscription = useSubscriptions();

  let emojiPickerStyling = "absolute top-[360px] left-0";
  if (screenWidth < 768) {
    emojiPickerStyling = "absolute bottom-28 inset-x-0";
  }

  React.useEffect(() => {
    props.setReassignmentInfo(value);
  }, [props, value]);

  React.useEffect(() => {
    if (loadingAiSummary) {
      console.log("loading");
    }
  }, [loadingAiSummary]);

  async function generateAiSummary() {
    setValue("AI is generating conversation summary...");
    setLoadingAiSummary(true);
    const response = await api.messaging.get(
      `ConversationNotes/Summary/${props.conversationId}`
    );
    const data = await response.json();
    setValue(data);
    setLoadingAiSummary(false);
    setShowAINotice(true);
  }

  return (
    <>
      <div className={loadingAiSummary == true ? "ql-aianimation" : ""}>
        <div
          className={
            loadingAiSummary == true
              ? "ql-aianimationcontent"
              : "border border-slate-gray-200 rounded-md "
          }
        >
          <ReactQuill
            className="flex flex-col border-none w-full "
            placeholder="Type your message"
            theme="snow"
            value={value}
            onChange={setValue}
            modules={modules}
          />
          <div className="flex flex-col">
            <div className="flex flex-row h-12 text-slate-gray-500 items-center gap-0.5 pl-2">
              {/* <button>
              <UploadButton
                onAddAttachments={(files) => {
                  setAttachments((attachments) => [...attachments, ...files]);
                }}
              />
            </button> */}
              <button
                className="h-8 w-8 flex items-center justify-center"
                onClick={(e) => {
                  setShowEmojiPicker(!showEmojiPicker);
                  e.stopPropagation();
                }}
              >
                <IconEmoji />
              </button>
              {props.conversationId != null &&
                subscription.subscription.enableAI == true && (
                  <>
                    <div className="h-6">
                      <Tooltip
                        text="AI Summary"
                        direction="top"
                        width="96px"
                        minWidth="96px"
                        offset="25px"
                      >
                        <button onClick={() => generateAiSummary()}>
                          <IconAIButton />
                        </button>
                      </Tooltip>
                    </div>
                  </>
                )}
            </div>
            {showEmojiPicker && (
              <div className={emojiPickerStyling}>
                <Picker
                  data={data}
                  theme={theme}
                  onEmojiSelect={(emoji: { native: string }) => {
                    setValue((oldState) => {
                      console.log(oldState.slice(0, oldState.length - 4));
                      return (
                        oldState.slice(0, oldState.length - 4) + emoji.native
                      );
                    });
                  }}
                  onClickOutside={() => setShowEmojiPicker(!showEmojiPicker)}
                  previewPosition={"none"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {showAINotice && (
        <div className="pt-4 text-slate-gray-500 animate-fade-in-quick">
          Generated with AI, please double-check its responses before sharing.
        </div>
      )}
    </>
  );
}
